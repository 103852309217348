import axios from 'axios'
import * as React from 'react'
import { useEffect, useState } from 'react'
import HallSessions from './HallSessions'

export const DAY_ONE_HALLS: Array<ProgrammeSession['hall']> = ['Lecture Hall A', 'Lecture Hall B', 'Lecture Hall C']
export const DAY_TWO_HALLS: Array<ProgrammeSession['hall']> = ['Lecture Hall A', 'Lecture Hall B']
export const DAY_THREE_HALLS: Array<ProgrammeSession['hall']> = ['Lecture Hall A', 'Humanitarian & Sports','Lightning Sessions (Free Paper Oral Presentations)']

export const WORKSHOP_HALLS: Array<ProgrammeSession['hall']> = ['Workshops']
export const SIG_HALLS: Array<ProgrammeSession['hall']> = ['SIG Meetings']


const EVENT_DATES = [
  { dayNum: 1, date: '15 June | Thursday' },
  { dayNum: 2, date: '16 June | Friday' },
  { dayNum: 3, date: '17 June | Saturday' },
]

export const FACULTY_TYPE = [
  'Chairperson',
  'Citation',
  'Co-Chair',
  'Emcee',
  'Judge',
  'Guest Of Honor',
  'Speaker',
  'Adjudicator',
  'Moderator'
] as const

export enum CATEGORY {
  'lecture_hall',
  'workshops',
  'sig_meetings',
}

export interface Faculty {
  id: number
  fullName: string
  avatar: string
  shortProfile: string
}

export interface FacultyWithType extends Faculty {
  facultyType: typeof FACULTY_TYPE[number]
}

export interface ProgrammeSession {
  id: number
  title: string
  desc?: string
  startAt: string
  endAt: string
  date: string // 11 JUN | SATURDAY
  time: string // 10:00 to 11:00
  day: 1 | 2 | 3
  hallOverwrite?: string
  hall: 'Lecture Hall A' | 'Lecture Hall B' | 'Lecture Hall C' |  'Humanitarian & Sports' | 'Lightning Sessions (Free Paper Oral Presentations)' | 'Workshops' | 'SIG Meetings'
  category: 'lecture_hall' | 'workshops' | 'sig_meetings'
  faculties: Array<FacultyWithType>
  subsessions: Array<ProgrammeSubsession>
  secondsTillStart: number
  durationInSeconds: number
  ended?: boolean
}

export interface ProgrammeSubsession {
  id: number
  title?: string
  description?: string
  speakers: Array<Faculty>
  time: string // 10:00 to 11:00
}

const Programme = ({ defaultDay }) => {
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState<CATEGORY>(CATEGORY.lecture_hall)
  const [day, setDay] = useState(defaultDay || 1)
  const [lectureHalls, setLectureHalls] = useState(defaultDay === 1 ? DAY_ONE_HALLS : defaultDay === 2 ? DAY_TWO_HALLS : DAY_THREE_HALLS)
  const [data, setData] = useState([])

  const [activeLecture, setActiveLecture] = useState<ProgrammeSession['hall']>('Lecture Hall A')

  useEffect(() => {
    setLoading(true)
    axios
      .get(
        `/api/programme_sessions?category=${CATEGORY[category]}&day=${day}`
      )
      .then((response) => {
        setData(response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [day, category])

  const dayClass = 'btn btn-outline-primary my-1 fw-bold me-2'
  const catClass = 'btn btn-outline-danger my-1 fw-bold me-2'

  const getTabColor = (hallname: string) => {
    switch (hallname) {
      case 'Lecture Hall A':
        return '#fcb1b1';
      case 'Lecture Hall B':
        return '#59d4e8';
      case 'Lecture Hall C':
        return '#96f7d2';
      case 'Humanitarian & Sports':
        return '#a6acec';
      case 'Lightning Sessions (Free Paper Oral Presentations)':
        return '#e8ffe8';
      case 'Workshops':
        return '#f5b5fc';
      case 'SIG Meetings':
        return '#b7e2ce';
      default:
        return '';
    }
  }


  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    )
  }

  return (
    <div className="container pb-5" id="programmeAgenda">
      <div className="filterBtns">
        <div className="my-3 text-center">
          <small className="d-block mb-1">DATE</small>
          <a href="/programme-overview">
            <button className={`${dayClass}`}>Programme Overview</button>
          </a>
          {EVENT_DATES.map(({ dayNum, date }) => (
            <button
              key={dayNum}
              className={`${dayNum === day && 'active'} ${dayClass}`}
              onClick={() => {
                setDay(dayNum)
                if (dayNum === 1) setLectureHalls(DAY_ONE_HALLS)
                if (dayNum === 2) setLectureHalls(DAY_TWO_HALLS)
                if (dayNum === 3) setLectureHalls(DAY_THREE_HALLS)
              }}
            >
              {date}
            </button>
          ))}
        </div>
        <div className="my-3 text-center">
          {/* <small className="d-block mb-1">VENUE</small> */}
          <button
            className={`${category === CATEGORY.lecture_hall && 'active'} ${catClass}`}
            onClick={() => setCategory(CATEGORY.lecture_hall)}
          >
            Lecture Halls
          </button>
          <button
            className={`${category === CATEGORY.workshops && 'active'} ${catClass}`}
            onClick={() => setCategory(CATEGORY.workshops)}
          >
            Workshops
          </button>
          <button
            className={`${category === CATEGORY.sig_meetings && 'active'} ${catClass}`}
            onClick={() => setCategory(CATEGORY.sig_meetings)}
          >
            SIG Meetings
          </button>
        </div>
      </div>


      {/* day1 - 3tabs, day2 - 2tabs, day3 - 3tabs */}
      {category === CATEGORY.lecture_hall && (
        <div className="mt-5">
          {/* mobile */}
          <div className="d-flex d-md-none">
            {lectureHalls.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && 'deselected'
                }`}
                style={{background: getTabColor(hall), textTransform: 'capitalize' }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall.replace('Lecture ','')}
              </button>
            ))}
          </div>
          {/* desktop */}
          <div className="row nogutters">
            {lectureHalls.map((hall) => (
              <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {category === CATEGORY.workshops && (
        <div className="mt-5">
          {/* mobile */}
          <div className="d-md-none">
            {WORKSHOP_HALLS.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold`}
                style={{background: getTabColor(hall), textTransform: 'capitalize' }}
              >
                {hall}
              </button>
            ))}
          </div>
          {/* desktop */}
          <div className="row nogutters">
            {WORKSHOP_HALLS.map((hall) => (
              <div className={"col-md-6"} key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={'Workshops'}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {category === CATEGORY.sig_meetings && (
        <div className="mt-5">
          {/* mobile */}
          <div className="d-md-none">
            {SIG_HALLS.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold`}
                style={{background: getTabColor(hall), textTransform: 'capitalize' }}
              >
                {hall}
              </button>
            ))}
          </div>
          {/* desktop */}
          <div className="row nogutters">
            {SIG_HALLS.map((hall) => (
              <div className={"col-md-6"} key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={"SIG Meetings"}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

    </div>
  )
}

export default Programme
