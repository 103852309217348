import * as React from 'react'

const AccessRight = ({ user }) => {
  const renderFlag = (bool) =>
    bool ? (
      <i className="fas fa-check" style={{ color: 'green' }}></i>
    ) : (
      <i className="fas fa-times" style={{ color: 'red' }}></i>
    )

  return (
    <>
      <div className="mt-3">Event Access Rights:</div>
      <div className="card mt-2 p-4 w-100">
        <div className="mb-2">
          <span className="fw-bold">Conference Pass:&nbsp;</span>
          {renderFlag(user.eventAccess)}
        </div>
        <div className="mb-2">
          <span className="fw-bold">Workshop 1:&nbsp;</span>
          {renderFlag(user.workshop1)}
        </div>
        <div className="mb-2">
          <span className="fw-bold">Workshop 2:&nbsp;</span>
          {renderFlag(user.workshop2)}
        </div>
      </div>

      <div className="mt-3">Member Type:</div>
      <div className="card mt-2 p-4 w-100">
        <div className="mb-2">
          <span className="fw-bold">Is Industry?&nbsp;</span>
          {renderFlag(user.memberType)}
        </div>
      </div>

      {/* <div className="mt-3">Abstracts Info:</div>
      <div className="card mt-2 p-4 w-100">
        <div className="mb-1">
          <span className="fw-bold">Is Oral Presenter?&nbsp;</span>
          {renderFlag(user.isOralPresenter)}
        </div>
        <small
          className="d-block"
          dangerouslySetInnerHTML={{ __html: user.oralAbs }}
        />
        <hr />
        <div className="mb-1">
          <span className="fw-bold">Is Finalist?&nbsp;</span>
          {renderFlag(user.isFinalist)}
        </div>
        <small
          className="d-block"
          dangerouslySetInnerHTML={{ __html: user.finalistAbs }}
        />
        <div className="mt-1">
          <small>
            <strong>Award Type:</strong> {user.finalistType}
          </small>
        </div>
        <hr />
        <div className="mb-1">
          <span className="fw-bold">Is Winner?&nbsp;</span>
          {renderFlag(user.isWinner)}
        </div>
        <small
          className="d-block"
          dangerouslySetInnerHTML={{ __html: user.winnerAbs }}
        />
      </div> */}
    </>
  )
}

export default AccessRight
