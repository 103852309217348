import axios from 'axios'
import React, { useState } from 'react'
import { ProductCard } from './ProductCard'

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  purchasedWorkshop,
  physical,
  biostatistics,
  ctilCtx,
  sst,
}) => {
  const [selected, setSelected] = useState(null)
  const [selectedBiostatistics, setSelectedBiostatistics] = useState(null) // [null, product_id]
  const [selectedCtilCtx, setSelectedCtilCtx] = useState(null) // [null, product_id]
  const today = new Date();
  const march = new Date(today.getFullYear(), 2, 1); // Months are 0-indexed in JavaScript

  let sstRate = today < march ? 6 : 8;

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedBiostatistics, selectedCtilCtx]}
        />

        <ProductCard
          cardTitle={'CRM Trial Connect 2024 Conference Ticket'}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null)
              selectedBiostatistics(null)
              selectedCtilCtx(null)
            }
          }}
          handleRadio={() => {
            setSelectedBiostatistics(null)
            setSelectedCtilCtx(null)
          }}
        />

        {/* only can buy one workshop */}
        <h4 className="text-center text-navy mt-4">Select Pre-Conference Workshops</h4>
        {/* ------------ biostatistics CARD ------------ */}
        <ProductCard
          cardTitle={'Workshop 1: Biostatistics in Oncology Research Collaborators: EORTC & NCCH '}
          products={biostatistics}
          selectedState={selectedBiostatistics}
          setSelectedState={setSelectedBiostatistics}
          activated={!purchasedWorkshop}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedBiostatistics(null)
              setSelectedCtilCtx(null)
            }
          }}
          handleRadio={() => {
            setSelectedBiostatistics(null)
            setSelectedCtilCtx(null)
          }}
        />
        <h6 className="text-center text-muted mt-4">OR</h6>
        {/* ------------ ctilCtx CARD ------------ */}
         <ProductCard
          cardTitle={'Workshop 2: CTIL/CTX Good Submission Practice Collaborators: NPRA'}
          products={ctilCtx}
          selectedState={selectedCtilCtx}
          setSelectedState={setSelectedCtilCtx}
          activated={!purchasedWorkshop}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedBiostatistics(null)
              setSelectedCtilCtx(null)
            }
          }}
          handleRadio={() => {
            setSelectedBiostatistics(null)
            setSelectedCtilCtx(null)
          }}
        />


        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur {sstRate}% SST
          </strong>
        )}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={selected === null && selectedBiostatistics === null && selectedCtilCtx === null}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  )
}

export default ProductSelection
